import React from 'react';
import PropTypes from 'prop-types';

export const IconCardContent = ({ title }) => {
  if (!title) return null;

  return (
    <div className="ids-doc content">
      <span className="ids-doc title">{title}</span>
    </div>
  );
};

IconCardContent.propTypes = {
  title: PropTypes.string
};
