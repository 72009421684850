import React from 'react';
import PropTypes from 'prop-types';

import { noop } from './lib';

import './styles/search.styl';

export const IconsSearch = ({ handleKeyUp }) => {
  const _handle_key_up = e => {
    requestAnimationFrame(() => {
      handleKeyUp(e);
    });
  };

  return (
    <div className="ids-doc search-field-holder">
      <form className="if" onSubmit={noop}>
        <div className="if search-field" role="search">
          <input
            type="search"
            placeholder="Search by name/tags"
            className="if"
            onKeyUp={_handle_key_up}
            id="iconsearch"
            name="iconsearch"
            aria-label="Search/Filter by"
          />
        </div>
      </form>
    </div>
  );
};

IconsSearch.propTypes = {
  handleKeyUp: PropTypes.func
};
