import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './styles/header.styl';

export const IconsHeader = ({ children }) => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const _handle_scroll = () => {
      window.requestAnimationFrame(() => {
        const _a = document.querySelector('html').scrollTop;

        if (_a === 0) {
          setHasScrolled(false);
        } else {
          setHasScrolled(true);
        }
      });
    };

    window.addEventListener('scroll', _handle_scroll);

    return () => window.addEventListener('scroll', _handle_scroll);
  }, []);

  const _class_names = cx('ids-doc icons-header', {
    'has-scrolled': hasScrolled
  });

  return (
    <>
      <header id="content" className={_class_names} style={{ overflow: 'visible' }}>
        <div className="if container fluid">
          <h1 className="if heading large">Icons</h1>
          <div className="ids-doc icons-articles">
            <a className="if ids-doc" href="/design/foundation/icons">
              Icon design guidelines
            </a>
            <a className="if ids-doc" href="/components/media/icons/css">
              How to use the icons in your code
            </a>
            <a className="if ids-doc" href="/components/media/icons">
              The Icon component
            </a>
          </div>
          <div>
            <a
              download="If-Icons-SVG.zip"
              className="if button secondary inverse"
              aria-label="Download If-Icons-SVG.zip"
              href="/If-Icons-SVG.zip">
              <svg xmlns="http://www.w3.org/2000/svg" className="if icon ui" viewBox="0 0 32 32">
                <g
                  className="nc-icon-wrapper"
                  strokeLinecap="square"
                  strokeLinejoin="miter"
                  strokeWidth="1.5"
                  fill="none"
                  stroke="currentColor">
                  <line data-cap="butt" x1="16" y1="2" x2="16" y2="24" strokeMiterlimit="10" strokeLinecap="butt" />
                  <polyline points="8 16 16 24 24 16" strokeMiterlimit="10" />
                  <polyline points="2 23 2 30 30 30 30 23" strokeMiterlimit="10" />
                </g>
              </svg>
              Download all our icons (zip)
            </a>
          </div>
        </div>
      </header>
      {children}
    </>
  );
};

IconsHeader.propTypes = {
  children: PropTypes.node
};
