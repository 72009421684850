import React from 'react';
import PropTypes from 'prop-types';

export const IconsFilterOption = ({ set, handleKeyUp, handleChange, isSelected }) => {
  const _is_selected = isSelected ? 'true' : 'false';
  const _handle_key_up = e => {
    handleKeyUp(e);

    if (e.key === 'Enter') {
      if (isSelected) {
        e.target.checked = false;
      } else {
        e.target.checked = true;
      }

      handleChange(e);
    }
  };

  return (
    <li className="if ids-doc">
      <input
        id={`icon-categories-option-${set.id}`}
        name={`icon-categories-option-${set.id}`}
        type="checkbox"
        role="option"
        onKeyUp={_handle_key_up}
        onChange={handleChange}
        aria-selected={_is_selected}
        data-rel={set.id}
        className="if checkbox"
      />
      <label className="if" htmlFor={`icon-categories-option-${set.id}`}>
        {set.label}
      </label>
    </li>
  );
};

IconsFilterOption.propTypes = {
  set: PropTypes.shape(),
  isSelected: PropTypes.bool,
  handleChange: PropTypes.func,
  handleKeyUp: PropTypes.func
};
