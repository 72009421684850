import React from 'react';
import PropTypes from 'prop-types';

import { IconsFilter } from './IconsFilter';
import { noop } from './lib';

import './styles/actions.styl';

export const IconsActions = ({ children, activeFilters, setActiveFilters, sets }) => {
  return (
    <section className="ids-doc icons-actions">
      <div className="if container fluid">
        {children}
        <div className="ids-doc controls">
          {sets && (
            <form className="if ids-doc" onSubmit={noop}>
              <IconsFilter activeFilters={activeFilters} setActiveFilters={setActiveFilters} sets={sets} />
            </form>
          )}
        </div>
      </div>
    </section>
  );
};

IconsActions.propTypes = {
  children: PropTypes.node,
  sets: PropTypes.array,
  activeFilters: PropTypes.array,
  handleChange: PropTypes.func,
  setActiveFilters: PropTypes.func
};
