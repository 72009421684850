import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { srSpeak } from '../../lib/a11y';
import { IconsFilterOption } from './IconsFilterOption';

import './styles/filter.styl';

export const IconsFilter = ({ setActiveFilters, activeFilters, sets }) => {
  if (!sets) return null;

  const _click_outside_ref = useRef();
  const _icon_filter_ref = useRef();
  const [_is_open, _set_is_open] = useState(false);
  const [_checked_filters, _set_checked_filters] = useState(0);
  const _handle_dropdown_trigger_click = e => {
    const _combobox_el = e.target;

    if (_is_open) {
      _set_is_open(false);
    } else {
      _set_is_open(true);

      const _combo_label = _combobox_el.getAttribute('aria-label');

      srSpeak(`${_checked_filters} selected from group ${_combo_label}`);
    }
  };
  const _handle_key_up = e => {
    if (e.key === 'Escape') {
      _set_is_open(false);
    }
  };
  const _handle_change = e => {
    const _option_el = e.target;
    const _is_selected = e.target.checked;
    const _selected_label = _option_el.parentElement.querySelector('label');

    _option_el.setAttribute('aria-selected', _is_selected);

    if (_is_selected) {
      _set_checked_filters(_checked_filters + 1);
      setActiveFilters([...new Set([...activeFilters, parseInt(e.target.dataset.rel)])]);
      srSpeak(`Selected ${_selected_label.textContent}`);
    } else {
      setActiveFilters(activeFilters.filter(filter => filter !== parseInt(e.target.dataset.rel)));
      _set_checked_filters(_checked_filters - 1);
    }
  };
  const _icon_filter_class_names = cx('if dropdown-filter ids-doc icon-filter', {
    'is-open': _is_open
  });
  const _handle_click_outside = e => {
    const _filter_el = e.target.closest('.ids-doc.icon-filter-holder');

    if (_filter_el && _filter_el.contains(e.target)) {
      return;
    }

    _set_is_open(false);
  };

  useEffect(() => {
    const _checked_filters = _icon_filter_ref.current.querySelectorAll('input:checked');
    const _number_of_checked_filters = _checked_filters.length;
    const activeFilters = Array.from(_checked_filters).map(filter => parseInt(filter.dataset.rel));

    _set_checked_filters(_number_of_checked_filters);
    setActiveFilters(activeFilters);
  }, []);

  useEffect(() => {
    _click_outside_ref.current = 'clickoutside';
  }, ['clickoutside']);

  useEffect(() => {
    document.addEventListener('click', _handle_click_outside);

    return () => {
      document.removeEventListener('click', _handle_click_outside);
    };
  }, [_click_outside_ref]);

  return (
    <div ref={_icon_filter_ref} className="if ids-doc icon-filter-holder dropdown-filter-holder">
      <button
        type="button"
        aria-controls="icon-categories"
        aria-haspopup="true"
        role="combobox"
        aria-owns="icon-categories"
        onKeyUp={_handle_key_up}
        onClick={_handle_dropdown_trigger_click}
        aria-expanded={_is_open ? 'true' : 'false'}
        aria-label="Icon category filter"
        className="if ids-doc icon-filter-button dropdown-filter-button">
        Show all icons
      </button>
      <div className={_icon_filter_class_names} id="icon-categories" role="listbox">
        <ul className="if is-open">
          {sets.map((set, index) => {
            const _is_selected = activeFilters.includes(set.id);

            return (
              <IconsFilterOption
                set={set}
                handleKeyUp={_handle_key_up}
                handleChange={_handle_change}
                isSelected={_is_selected}
                key={`icon-categories-option-${index}`}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

IconsFilter.propTypes = {
  sets: PropTypes.array,
  activeFilters: PropTypes.array,
  handleChange: PropTypes.func,
  setActiveFilters: PropTypes.func
};
