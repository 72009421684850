import React from 'react';
import PropTypes from 'prop-types';

import { IconCardFlag } from './IconCardFlag';
import { IconCardDefault } from './IconCardDefault';

import './styles/card.styl';

export const IconCard = ({ icons, icon }) => {
  if (!icons || !icon) return null;

  if (icon.set_id >= 6) {
    return <IconCardFlag icon={icon} icons={icons} />;
  }

  return <IconCardDefault icon={icon} icons={icons} />;
};

IconCard.propTypes = {
  icons: PropTypes.array,
  icon: PropTypes.shape()
};
