export const onSuccess = message => {
  const _dispatchToastEvent = ({ ...args }) => {
    const _event = new CustomEvent('ids:toast', {
      bubbles: !0,
      detail: {
        payload: { ...args }
      }
    });

    document.dispatchEvent(_event);
  };

  _dispatchToastEvent({
    message,
    type: 'info'
  });
};

export const onError = message => {
  const _dispatchToastEvent = ({ ...args }) => {
    const _event = new CustomEvent('ids:toast', {
      bubbles: !0,
      detail: {
        payload: { ...args }
      }
    });

    document.dispatchEvent(_event);
  };

  _dispatchToastEvent({
    message,
    type: 'error'
  });
};
