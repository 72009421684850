import React from 'react';
import PropTypes from 'prop-types';

import { groupBy, getIconSetLabel } from './lib';
import { IconSection } from './IconSection';
import { EmptyState } from '../Layout';

import './styles/sections.styl';

export const IconSections = ({ activeFilters, query, sets, icons }) => {
  if (!icons || !sets) return null;

  if (query && query !== '' && icons.length == 0) {
    return (
      <section className="ids-doc icon-sections if block section">
        <div className="if container fluid">
          <EmptyState>
            <p className="if text body">
              There is currently no icons matching the query{' '}
              <em className="if">
                <strong className="if">{query}</strong>
              </em>
              .
              <br />
              <a
                href="https://teams.microsoft.com/l/team/19%3a5653139ad54d4f06b340907b42f35aeb%40thread.skype/conversations?groupId=5f5dd61d-c19e-437a-9f65-721db7ef30b7&tenantId=de7e7a67-ae61-49d2-97a7-526c910ad675"
                target="_blank"
                rel="noreferrer noopener"
                className="if ids-doc">
                Contact the Design System team<span className="if axe sr-only">, Opens in new window</span>
              </a>{' '}
              for questions about missing icons or requests for new icons.
            </p>
          </EmptyState>
        </div>
      </section>
    );
  }

  if (query && query !== '' && query.length >= 2 && (!activeFilters || (activeFilters && activeFilters.length === 0))) {
    return (
      <section className="ids-doc icon-sections if block section">
        <div className="if container fluid">
          <IconSection icons={icons} />
        </div>
      </section>
    );
  }

  const _grouped = groupBy(icons, 'set_id');
  const _icon_keys = Object.keys(_grouped);

  return (
    <section className="ids-doc icon-sections if block section">
      <div className="if container fluid">
        {_icon_keys.map((key, index) => {
          const _group = _grouped[key];
          const _title = getIconSetLabel(key, sets);

          return <IconSection id={key} title={_title} key={`icon-group-${index}`} icons={_group} />;
        })}
      </div>
    </section>
  );
};

IconSections.propTypes = {
  icons: PropTypes.array,
  query: PropTypes.string,
  activeFilters: PropTypes.array,
  sets: PropTypes.array
};
