import React from 'react';
import PropTypes from 'prop-types';

import { handleClick } from './lib';

export const IconCardActions = ({ icons, icon }) => {
  if (!icons || !icon) return null;

  const _handle_click = e => {
    handleClick(e, icons);
  };

  return (
    <div className="ids-doc actions">
      <button onClick={_handle_click} type="button" data-rel={icon.name} className="if primary button">
        Download
      </button>
    </div>
  );
};

IconCardActions.propTypes = {
  icons: PropTypes.array,
  icon: PropTypes.shape()
};
