import React, { useState, useMemo } from 'react';
import NUCLEO_ICONS from '@ids-core/icons/src/nucleo/if_icons.json';

import debounce from '../../../lib/debounce';

import { Layout } from '../../../components/Layout';
import { SEO } from '../../../components/SEO';
import { BackToTop } from '../../../components/BackToTop';
import { IconSections, IconsHeader, IconsActions, IconsSearch } from '../../../components/Icons';

import { getPrunedIconData } from '../../../components/Icons/lib';

import '../../../components/Icons/styles/icons.styl';

const IconsPage = () => {
  const { icons, sets } = getPrunedIconData(NUCLEO_ICONS);
  const [_query, _set_query] = useState('');
  const [_active_filters, _set_active_filters] = useState([]);
  const _handle_key_up = debounce(e => {
    let _string = '' || e.target.value;

    _string = _string.trim().replace(/ +/g, ' ').toLowerCase();

    _set_query(_string);
  }, 300);
  const _get_icons = (query, activeFilters) => {
    if (!query || (query && query === '')) {
      if (activeFilters && activeFilters.length !== 0) {
        return icons.filter(icon => _active_filters.includes(icon.set_id));
      }

      return icons;
    } else {
      if (query.length <= 1) {
        return icons;
      }

      return icons.filter(icon => {
        const _text = icon.tags
          .split(',')
          .map(tag => tag.trim())
          .join(' ')
          .toLowerCase();

        let _matches = true;

        const _query_values = _query.split(' ');

        _query_values.forEach(value => {
          _matches = !_matches ? false : _text.indexOf(value) !== -1;
        });

        return _matches;
      });
    }
  };
  const _memoised_icons = useMemo(() => _get_icons(_query, _active_filters), [_query, _active_filters]);

  return (
    <Layout fullPage={true} fluid={true} header={true}>
      <SEO />
      <IconsHeader>
        <IconsActions activeFilters={_active_filters} setActiveFilters={_set_active_filters} sets={sets}>
          <IconsSearch handleKeyUp={_handle_key_up} />
        </IconsActions>
      </IconsHeader>
      <IconSections query={_query} activeFilters={_active_filters} icons={_memoised_icons} sets={sets} />
      <BackToTop />
    </Layout>
  );
};

export default IconsPage;
