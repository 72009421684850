export const getPrunedIconData = json => {
  const { icons, sets } = json;
  const _icons = icons.map(icon => {
    // flag icons starts with set_id 6
    if (icon.set_id >= 6) {
      return { ...icon, set_id: 6 };
    }

    return icon;
  });
  // just get the first flag set, we do not need the others
  const _sets = sets
    .filter(set => set.id <= 6)
    .map(set => {
      if (set.id == 6) {
        return { ...set, label: 'Flags' };
      }

      return set;
    });

  return {
    icons: _icons,
    sets: _sets
  };
};

export const getIconSetLabel = (setID, SETS) => SETS.find(set => set.id == setID).label;

export const getCountryName = code => {
  let _country_name;

  if (code === 'es-ct') {
    _country_name = 'Spain - Catalonia';
  } else if (code === 'es-ga') {
    _country_name = 'Spain - Galicia';
  } else if (code === 'gb-eng') {
    _country_name = 'England';
  } else if (code === 'gb-nir') {
    _country_name = 'Northern Ireland';
  } else if (code === 'gb-sct') {
    _country_name = 'Scotland';
  } else if (code === 'gb-wls') {
    _country_name = 'Wales';
  } else {
    const _region_names = new Intl.DisplayNames(['en'], { type: 'region' });

    _country_name = _region_names.of(code.toUpperCase());
  }

  return _country_name;
};

export const groupBy = (list, key) =>
  [...list].reduce((acc, x) => {
    const group = x[key];

    if (!acc[group]) {
      return {
        ...acc,
        [group]: [x]
      };
    }

    return {
      ...acc,
      [group]: [...acc[group], x]
    };
  }, {});
