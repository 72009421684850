import React from 'react';
import PropTypes from 'prop-types';

import { getCountryName } from './lib';

import { IconCardActions } from './IconCardActions';
import { IconCardContent } from './IconCardContent';

export const IconCardFlag = ({ icons, icon }) => {
  if (!icons || !icon) return null;

  const _title = getCountryName(icon.name);

  return (
    <li className="ids-doc icon-card" data-rel={icon.name} data-text={`if icon flag ${icon.name}`}>
      <IconCardActions icon={icon} icons={icons} />
      <div className="ids-doc example">
        <span
          className="ids-doc icon flag"
          style={{ height: '2rem', width: '2rem' }}
          dangerouslySetInnerHTML={{
            __html: icon.content
          }}></span>
      </div>
      <IconCardContent title={_title} />
    </li>
  );
};

IconCardFlag.propTypes = {
  icons: PropTypes.array,
  icon: PropTypes.shape()
};
