import { saveFile, onError } from './';

export const handleClick = (e, icons) => {
  const _button_el = e.target;
  const _icon_name = _button_el.getAttribute('data-rel');

  if (!_icon_name || (_icon_name && _icon_name === '')) return;

  const icon = icons.filter(icon => {
    return icon.name === _icon_name;
  })[0];

  try {
    const blob = new Blob([icon.content], {
      type: 'image/svg+xml'
    });

    saveFile(blob, `${_icon_name}.svg`);
  } catch (e) {
    onError('Could not download icon, see console');
    throw e;
  }
};
