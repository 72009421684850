import React from 'react';
import PropTypes from 'prop-types';

import { IconCard } from './IconCard';

export const IconCards = ({ icons }) => {
  if (!icons) return null;

  return (
    <ul className="ids-doc cards icons">
      {icons.map((icon, index) => {
        return <IconCard key={`icon-card-key-${index}`} icons={icons} icon={icon} />;
      })}
    </ul>
  );
};

IconCards.propTypes = {
  icons: PropTypes.array
};
