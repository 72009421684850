import React from 'react';
import PropTypes from 'prop-types';

import { IconCards } from './IconCards';

import './styles/cards.styl';

export const IconSection = ({ id, icons, title }) => {
  if (!icons) return null;

  return (
    <>
      {title && (
        <h2 className="if heading medium" id={`icon-group-heading-${id}`}>
          {title}
        </h2>
      )}
      <IconCards icons={icons} />
    </>
  );
};

IconSection.propTypes = {
  icons: PropTypes.array,
  title: PropTypes.string,
  id: PropTypes.string
};
