import React from 'react';
import PropTypes from 'prop-types';
import humanize from 'humanize-string';

import { IconCardActions } from './IconCardActions';
import { IconCardContent } from './IconCardContent';

export const IconCardDefault = ({ icons, icon }) => {
  if (!icons || !icon) return null;

  const _title = humanize(icon.name);
  const _content = icon.content
    .replace(/fill="#331E11"/gi, 'fill="currentColor"')
    .replace(/stroke="#331E11"/gi, 'stroke="currentColor"');

  return (
    <li className="ids-doc icon-card" data-text={`if icon ${icon.name}`}>
      <IconCardActions icon={icon} icons={icons} />
      <div className="ids-doc example">
        <span
          className="ids-doc icon"
          style={{ height: '2rem', width: '2rem' }}
          dangerouslySetInnerHTML={{
            __html: _content
          }}></span>
      </div>
      <IconCardContent title={_title} />
    </li>
  );
};

IconCardDefault.propTypes = {
  icons: PropTypes.array,
  icon: PropTypes.shape()
};
