import { onSuccess } from './toasts';

export const saveFile = (blob, filename) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');

    document.body.appendChild(a);

    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      onSuccess(`Downloaded ${filename}`);
    }, 0);
  }
};
